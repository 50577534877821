<template>
  <div>
    <!-- <div class="row justify-content-center">
    <div class="col-12">
      <div class="card card-custom">
        <div class="card-body p-0">
          <div
            class="wizard wizard-1"
            id="kt_wizard_v1"
            data-wizard-state="step-first"
            data-wizard-clickable="true"
          >
            <div
              class="row justify-content-center my-10 px-8 my-lg-10 px-lg-8"
            >
              <div class="col-xl-12">
                <Form
                  :data="data"
                  :route="'tenants/'+data.id"
                  purpose="edit"
                  title="Informasi Tenant"
                  routeBack="/registrants/"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> -->
    <div class="row">
      <div class="col-md-12">
        <Card :title="'Detail Tenant'">
          <template v-slot:body>
            <div class="row align-items-center">
              <div class="col-md-4">
                <div
                  class="d-flex justify-content-center align-items-center image-container"
                >
                  <div style="height: 350px; width: 350px; position: relative">
                    <img
                      class="image"
                      src="/img/tenants/server.svg"
                      @load="onImageLoad()"
                    />
                  </div>
                </div>
              </div>

              <div class="col" v-if="imgLoaded != false">
                <table class="table mb-3">
                  <tr>
                    <td><strong>Nama Klinik</strong></td>
                    <td>{{ data.clinic_name }}</td>
                  </tr>
                  <tr>
                    <td><strong>Domain</strong></td>
                    <td>{{ data.domain }}</td>
                  </tr>
                  <tr>
                    <td><strong>Database Name</strong></td>
                    <td>{{ data.database_name }}</td>
                  </tr>
                  <tr>
                    <td><strong>Database IP</strong></td>
                    <td>{{ data.database_ip }}</td>
                  </tr>
                  <tr>
                    <td><strong>Status</strong></td>
                    <td>
                      {{
                        data.registrant_status == 0 ? "Baru" : "Terkonfirmasi"
                      }}
                    </td>
                  </tr>
                </table>
                <button
                  class="btn mx-1 btn-secondary"
                  @click="$router.push('/registrants')"
                >
                  <i class="fas fa-fw fa-arrow-left"></i> Kembali
                </button>
                <button
                  @click="
                    $router.push({
                      path: '/tenants/edit/' + data.id,
                    })
                  "
                  class="btn mx-1 btn-success"
                >
                  <i class="far fa-fw fa-edit"></i> Edit
                </button>
                <button
                  class="btn mx-1 btn-primary"
                  @click="createDatabase"
                  v-if="data.registrant_status == 0"
                >
                  <i class="fas fa-fw fa-database"></i> Buat Database
                </button>
                <!-- <button class="btn mx-1 btn-danger">
                  <i class="far fa-fw fa-trash-alt"></i> Hapus
                </button> -->
              </div>
            </div>
          </template>
        </Card>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Form from "@/view/components/tenants/Form.vue";
import Card from "@/view/content/Card.vue";
import module from "@/core/modules/CrudModule.js";
import moduleSaas from "@/core/modules/CrudModuleSaas.js";
import apiSaas from "@/core/services/apisaas.service.js";
import api from "@/core/services/api.service.js";
// import axios from "axios";

export default {
  components: {
    Form,
    Card,
  },
  data() {
    return {
      data: {},
      imgLoaded: true,
      form: {},
    };
  },
  methods: {
    async get() {
      this.data = await module.get(
        "tenants/get-by-subdomain/" + this.$route.params.id
      );
      // If Data Not Found
      if (this.data == null) {
        // Redirect To List
        this.$router.push("/registrants/");
      } else {
        this.data["_method"] = "put";
        this.form.database_name = this.data.database_name;
        this.form.subdomain = this.data.subdomain;
        apiSaas.setTenantConfig(this.data);
        console.log("set setTenantConfig");
      }
    },

    onImageLoad() {
      this.imgLoaded = true;
    },

    async createDatabase() {
      try {
        // Membuat database
        let response = await moduleSaas.createDb(
          `database/create?database_name=${this.data.database_name}&subdomain=${this.data.subdomain}`
        );

        if (!response.status) return;

        if (
          response.data.hasOwnProperty("is_migrate") &&
          response.data.is_migrate === 0
        ) {
          console.log("migrate");

          // Jalankan migration
          let migrationResponse = await moduleSaas.createMigration(
            `database/create/migrations`
          );
          if (migrationResponse.state !== "success")
            throw new Error("Migration failed");

          // Eksekusi migrasi utama dan insert awal
          const migrationSteps = [
            "database/migrate",
            "database/insert/access-list-position",
            "database/insert/user",
            "database/insert/region",
            "database/migrate/extend",
            "database/migrate/extend2",
            "database/migrate/extend3",
            "database/migrate/extend4",
            "database/migrate/extend5",
            "database/migrate/extend6",
          ];

          let extend6Result = null;

          for (let step of migrationSteps) {
            let result = await moduleSaas.migrateDb(step, this.form);
            if (result.state !== "success")
              throw new Error(`Error in step: ${step}`);

            if (step === "database/migrate/extend6") {
              extend6Result = result;
            }
          }

          // Pastikan extend6 berhasil sebelum lanjut ke bridgeRegister
          if (extend6Result && extend6Result.state === "success") {
            console.log("extend 6", extend6Result);
            this.bridgeRegister(extend6Result.success);
          }

          // Refresh data dan lanjutkan registrasi
          this.get();
          Swal.fire("Berhasil", "Berhasil membuat database", "success");
          await module.submitWithoutAlert(
            {},
            `registrants/${this.data.registrant_id}/success-generate`
          );
        } else {
          if (response.data.message === "Database Already Exist") {
            Swal.fire("", response.data.message, "info");
          }
          console.log("Database already exists, skipping migration.");
        }
      } catch (error) {
        console.error(error);
        Swal.fire("Gagal", "Gagal membuat database", "error");
      }
    },

    // async createDatabase() {
    //   // SwalLoading.fire();

    //   // Make Request
    //   let response = await moduleSaas.createDb(
    //     `database/create?database_name=${this.data.database_name}&subdomain=${this.data.subdomain}`
    //   );
    //   console.log("response CREATE", response);
    //   // Check Response
    //   if (
    //     response.status &&
    //     response.data.hasOwnProperty("is_migrate") &&
    //     response.data.is_migrate == 0
    //   ) {
    //     console.log("migrate");
    //     // MAKE MIGRATION TABLE
    //     let rest = await moduleSaas.createMigration(
    //       `database/create/migrations`
    //     );
    //     console.log("rest", rest);
    //     if (rest.state == "success") {
    //       // MIGRATE AND INSERT SOME TABLE
    //       let result = await moduleSaas.migrateDb(
    //         `database/migrate`,
    //         this.form
    //       );
    //       if (result.state == "error") {
    //         console.log("error", result);
    //       } else {
    //         // INSET ACCESS LIST POSITION
    //         let data = await moduleSaas.migrateDb(
    //           `database/insert/access-list-position`,
    //           this.form
    //         );
    //         if (data.state == "success") {
    //           let insert = await moduleSaas.migrateDb(
    //             `database/insert/user`,
    //             this.form
    //           );
    //           if (insert.state == "success") {
    //             let insert2 = await moduleSaas.migrateDb(
    //               `database/insert/region`,
    //               this.form
    //             );
    //             if (insert2.state == "success") {
    //               let extend = await moduleSaas.migrateDb(
    //                 `database/migrate/extend`,
    //                 this.form
    //               );
    //               if (extend.state == "success") {
    //                 let extend2 = await moduleSaas.migrateDb(
    //                   `database/migrate/extend2`,
    //                   this.form
    //                 );
    //                 if (extend2.state == "success") {
    //                   let extend3 = await moduleSaas.migrateDb(
    //                     `database/migrate/extend3`,
    //                     this.form
    //                   );
    //                   if (extend3.state == "success") {
    //                     let extend4 = await moduleSaas.migrateDb(
    //                       `database/migrate/extend4`,
    //                       this.form
    //                     );
    //                     if (extend4.state == "success") {
    //                       let extend5 = await moduleSaas.migrateDb(
    //                         `database/migrate/extend5`,
    //                         this.form
    //                       );
    //                       if (extend5.state == "success") {
    //                         this.get();
    //                         console.log("result", extend);
    //                         let extend6 = await moduleSaas.migrateDb(
    //                           `database/migrate/extend6`,
    //                           this.form
    //                         );
    //                         if (extend6.state == "success") {
    //                           console.log('extend 6', extend6)
    //                           this.get();
    //                           this.bridgeRegister(extend6.success)
    //                           Swal.fire("Berhasil", "Berhasil membuat database", "success");
    //                           await module.submitWithoutAlert({}, `registrants/${this.data.registrant_id}/success-generate`)
    //                         } else {
    //                           console.log("error", extend);
    //                           Swal.fire("Gagal", "Gagal membuat database", "error");
    //                         }
    //                       } else {
    //                         console.log("error", extend);
    //                         Swal.fire("Gagal", "Gagal membuat database", "error");
    //                       }
    //                     } else {
    //                       console.log("error", extend);
    //                       Swal.fire("Gagal", "Gagal membuat database", "error");
    //                     }
    //                   } else {
    //                     console.log("error", extend);
    //                     Swal.fire("Gagal", "Gagal membuat database", "error");
    //                   }
    //                 } else {
    //                   console.log("error", extend);
    //                   Swal.fire("Gagal", "Gagal membuat database", "error");
    //                 }
    //               } else {
    //                 console.log("error", extend);
    //                 Swal.fire("Gagal", "Gagal membuat database", "error");
    //               }
    //             } else {
    //             }
    //           } else {
    //             console.log("error", insert);
    //             Swal.fire("Gagal", "Gagal membuat database", "error");
    //           }
    //         } else {
    //           Swal.fire("Gagal", "Gagal membuat database", "error");
    //           console.log("error", data);
    //         }
    //       }
    //     }
    //   } else {
    //     if (response.data.message == "Database Already Exist") {
    //       SwalLoading.close();
    //       Swal.fire("", response.data.message, "info");
    //     }
    //     console.log(" dont run migrate");
    //   }
    // },

    async createDatabaseV2() {
      // SwalLoading.fire();

      // Make Request
      let response = await moduleSaas.createDb(
        `database/create?database_name=${this.data.database_name}&subdomain=${this.data.subdomain}`
      );
      console.log("response CREATE", response);
      // Check Response
      if (
        response.status &&
        response.data.hasOwnProperty("is_migrate") &&
        response.data.is_migrate == 0
      ) {
        console.log("migrate");
        // MAKE MIGRATION TABLE
        let rest = await moduleSaas.createMigration(
          `database/create/migrations`
        );
        console.log("rest", rest);
        if (rest.state == "success") {
          // MIGRATE AND INSERT SOME TABLE
          let create1 = await moduleSaas.migrateDb(
            `database/create/v2-1`,
            this.form
          );
          if (create1.state == "success") {
            let create2 = await moduleSaas.migrateDb(
              `database/create/v2-2`,
              this.form
            );
            if (create2.state == "success") {
              let create3 = await moduleSaas.migrateDb(
                `database/create/v2-3`,
                this.form
              );
              if (create3.state == "success") {
                let create4 = await moduleSaas.migrateDb(
                  `database/create/v2-4`,
                  this.form
                );
                if (create4.state == "success") {
                  let create5 = await moduleSaas.migrateDb(
                    `database/create/v2-5`,
                    this.form
                  );
                  if (create5.state == "success") {
                    this.get();
                    Swal.fire(
                      "Berhasil",
                      "Berhasil membuat database",
                      "success"
                    );
                  } else {
                    console.log("error", create1);
                    Swal.fire("Gagal", "Gagal membuat database", "error");
                  }
                } else {
                  console.log("error", create5);
                  Swal.fire("Gagal", "Gagal membuat database", "error");
                }
              } else {
                console.log("error", create4);
                Swal.fire("Gagal", "Gagal membuat database", "error");
              }
            } else {
              console.log("error", create3);
              Swal.fire("Gagal", "Gagal membuat database", "error");
            }
          } else {
            console.log("error", create2);
            Swal.fire("Gagal", "Gagal membuat database", "error");
          }
          // ========================================================================
          // if (create1.state == "success") {
          //   this.get();
          //   Swal.fire("Berhasil", "Berhasil membuat database", "success");
          // } else {
          //   console.log("error", create1);
          //   Swal.fire("Gagal", "Gagal membuat database", "error");
          // }
          // ========================================================================
        } else {
          console.log("error", rest);
          Swal.fire("Gagal", "Gagal membuat database", "error");
        }
      } else {
        if (response.data.message == "Database Already Exist") {
          SwalLoading.close();
          Swal.fire("", response.data.message, "info");
        }
        console.log(" dont run migrate");
      }
    },

    async createDatabaseV2FullVersion() {
      // SwalLoading.fire();

      // Make Request
      let response = await moduleSaas.createDb(
        `database/create?database_name=${this.data.database_name}&subdomain=${this.data.subdomain}`
      );
      console.log("response CREATE", response);
      // Check Response
      if (
        response.status &&
        response.data.hasOwnProperty("is_migrate") &&
        response.data.is_migrate == 0
      ) {
        console.log("migrate");
        // MAKE MIGRATION TABLE
        let rest = await moduleSaas.createMigration(
          `database/create/migrations`
        );
        console.log("rest", rest);
        if (rest.state == "success") {
          // MIGRATE AND INSERT SOME TABLE
          let create = await moduleSaas.migrateDb(
            `database/create/v2-full-version`,
            this.form
          );
          if (create.state == "success") {
            this.get();
            Swal.fire("Berhasil", "Berhasil membuat database", "success");
          } else {
            console.log("error", create);
            Swal.fire("Gagal", "Gagal membuat database", "error");
          }

          // Kirim pesan WA ke pendaftar berhasil generate akun
          // await module.submit({}, `registrants/{id}/success-generate`)
          // ========================================================================
          // if (create1.state == "success") {
          //   this.get();
          //   Swal.fire("Berhasil", "Berhasil membuat database", "success");
          // } else {
          //   console.log("error", create1);
          //   Swal.fire("Gagal", "Gagal membuat database", "error");
          // }
          // ========================================================================
        } else {
          console.log("error", rest);
          Swal.fire("Gagal", "Gagal membuat database", "error");
        }
      } else {
        if (response.data.message == "Database Already Exist") {
          SwalLoading.close();
          Swal.fire("", response.data.message, "info");
        }
        console.log(" dont run migrate");
      }
    },

    bridgeRegister(data) {
      let form = {
        name: data.registrant.clinic_name,
        database_name: data.tenant.database_name,
        database_ip: data.tenant.database_ip,
        client_id: data.oauth.id,
        client_secret: data.oauth.secret,
      };
      api.bridge
        .post("client-store", form)
        .then((response) => {
          console.log("bridgeRegister response success", response);
          if (response.data.status == "success") {
            this.updateSetting(response.data);
          }
        })
        .catch((error) => {
          console.log("bridgeRegister response error", error.response);
        });
    },

    async updateSetting(data) {
      let form = {
        clinic_id: data.clinic.id,
      };
      // Make Request
      let response = await moduleSaas.submitWithoutAlert(
        form,
        `settings-update-client-id/1`
      );
      console.log("response", response);
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Tenant", route: "/tenants/" },
      { title: "Informasi Tenant" },
    ]);

    this.get();
  },
};
</script>

<style scoped>
.image {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}
</style>